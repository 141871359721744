import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticateRoute from './auth/AuthenticateRoute';
import ErrorBoundary from './ErrorBoundary';
import { ContainerLoader } from '../components/loader';

// lazy loaded components (components should have a default export to lazy load)
const Auth = lazy(() => import('./auth'));
const Home = lazy(() => import('./home'));
const NotFound = lazy(() => import('./notfound'));

const AdminUser = lazy(() => import('./admin-user'));
const ErpUser = lazy(() => import('./erp-user'));
const Yard = lazy(() => import('./yard'));
const LicenseType = lazy(() => import('./license-type'));
const LectureHall = lazy(() => import('./lecture-hall'));
const TrainingAndTestingRequirement = lazy(() =>
  import('./training-testing-requirement')
);
const FeeCode = lazy(() => import('./fee-code'));
const CountryGroup = lazy(() => import('./country-group'));
const BranchType = lazy(() => import('./branch-type'));
const LowCreditSettings = lazy(() => import('./low-credit-setting'));
const Branch = lazy(() => import('./branch'));
const Instance = lazy(() => import('./instance'));
const DocumentType = lazy(() => import('./document-type'));
const CourseTypes = lazy(() => import('./course-type'));
const CourseTypeIncentive = lazy(() => import('./course-type-incentive'));
const Courses = lazy(() => import('./course'));
const Promotions = lazy(() => import('./promotion'));
const Company = lazy(() => import('./company'));
const ErrorCodeCategory = lazy(() => import('./error-code-category'));
const ErrorCode = lazy(() => import('./error-code'));
const Instructor = lazy(() => import('./instructor'));
const CsrStudentRegistration = lazy(() => import('./csr-student-registration'));
const StudentRegistration = lazy(() => import('./student-registration'));
const StudentTransfer = lazy(() => import('./student-transfer'));
const StudentRefund = lazy(() => import('./student-refund'));
const CsrLeadQualification = lazy(() => import('./csr-lead-qualification'));
const StudentRoutes = lazy(() => import('./student'));
// const StudentCrudUploadFile = lazy(() => import('./student/CrudUploadFile'));
//  const StudentCrudView = lazy(() => import('./student/CrudView'));
const StudentStage = lazy(() => import('./student-by-stage'));
const LeaveSummary = lazy(() => import('./leave-summary'));
const CsrDropOffRegistration = lazy(() =>
  import('./csr-drop-off-registration')
);
const WorkingHoursPracticalTraining = lazy(() => import('./working-hour'));
const WorkingHoursTheory = lazy(() => import('./working-hour-theory'));
const WorkingHoursMockTest = lazy(() => import('./working-hour-mock-test'));
const WorkingHoursSimulatorTraining = lazy(() =>
  import('./working-hour-simulator-training')
);
const LectureHallSchedule = lazy(() => import('./lecture-hall-schedule'));
const LectureTheory = lazy(() => import('./lecture-theory'));
const ApiAccessLogs = lazy(() => import('./api-access-logs'));
const Language = lazy(() => import('./language'));
const RisLanguage = lazy(() => import('./ris-language'));
const TaxClass = lazy(() => import('./tax-class'));
const PaymentReport = lazy(() => import('./payment-report'));
const TrainingInfo = lazy(() => import('./integrator-traininginfo'));
const PaymentClearance = lazy(() => import('./integrator-paymentclearance'));
const CTSStudentProfileInfo = lazy(() => import('./cts-studentprofileinfo'));
const CTSScheduleClass = lazy(() => import('./cts-scheduleclass'));
const RamadanSettings = lazy(() => import('./ramadan-settings'));
const Cashier = lazy(() => import('./cashier'));
const ServiceHeartBeat = lazy(() => import('./service-heart-beat'));
const RPCHeartBeat = lazy(() => import('./rpc-heart-beat'));
const CTSLectureAttendanceInfo = lazy(() =>
  import('./cts-lectureattendanceinfo')
);
const CTSAssessmentBookingInfo = lazy(() =>
  import('./cts-assessmentbookinginfo')
);
const CSRCollectionReport = lazy(() => import('./csr-collection-report'));
const Holiday = lazy(() => import('./holiday'));
const TrainingData = lazy(() => import('./integrator-fetchRtaDI'));
const CTSAssessmentResult = lazy(() => import('./cts-fetcherAssessmentResult'));
const CTSAttendanceResult = lazy(() => import('./cts-fetcherAttendanceResult'));
const CTSInstructorAlert = lazy(() => import('./cts-fetcherinstructoralert'));
const StudentCDD = lazy(() => import('./di-recieverstudentcdd'));
const ExamDetails = lazy(() => import('./di-recieverexamdetails'));
const RescheduleWarning = lazy(() => import('./di-receiverreschedulewarning'));
const IntegratorDashboard = lazy(() => import('./integrator-dashboard'));
const Contract = lazy(() => import('./contract'));
const IntegratorLog = lazy(() => import('./integrator-logs'));
const UploadTrainingInfo = lazy(() => import('./upload-training-info'));
const ChangeInstructorOfSchedules = lazy(() =>
  import('./change-instructor-of-schedules')
);
const ExamResult = lazy(() => import('./di-recieverexamresult'));
const SupervisorDashboard = lazy(() => import('./supervisor-dashboard'));
const SanityLog = lazy(() => import('./cts-sanityLog'));
const TrainingSchedule = lazy(() => import('./training-schedule'));
const CtsStudentPermitDetails = lazy(() =>
  import('./cts-studentpermit-details')
);
const InstructorUtilizationReport = lazy(() =>
  import('./instructor-utilization-report')
);
const RegistrationReport = lazy(() => import('./registration-report'));
const TestReport = lazy(() => import('./test-report'));
const MockTestReport = lazy(() => import('./mock-test-report'));
const InstructorApiReport = lazy(() => import('./instructor-api-report'));

const InstructorPassingReport = lazy(() =>
  import('./instructor-passing-report')
);
const InstructorPassingPerformanceReport = lazy(() =>
  import('./instructor-passing-performance-report')
);
const AdditionalTrainingLog = lazy(() => import('./additional-training-log'));
const SmsLog = lazy(() => import('./sms-log'));
const DownloadHistory = lazy(() => import('./download-history'));
const CMSEmail = lazy(() => import('./cms-email'));
const CMSNotification = lazy(() => import('./cms-notification'));
const CMSSms = lazy(() => import('./cms-sms'));
const CTSAbsentClasses = lazy(() => import('./cts-absent-classes'));
const PrivilegedCSR = lazy(() => import('./privillaged-csr-dashboard'));
const CustomerDropOffRegistration = lazy(() =>
  import('./customer-drop-off-registration')
);
const CsrStudentTransfer = lazy(() => import('./csr-student-transfer'));
const ApiPermissionsView = lazy(() => import('./api-permissions'));
const ApiRolePermissionsView = lazy(() => import('./api-role-permissions'));
const TopRatedInstructors = lazy(() => import('./top-rated-instructors'));
const CustomerRatingOfInstructor = lazy(() =>
  import('./customer-rating-of-instructor')
);
const SupervisorSchedules = lazy(() => import('./supervisor-schedules'));
const PendingSchedules = lazy(() => import('./pending-schedules'));
const BookAappointment = lazy(() => import('./book-an-appointment'));
const CustomerFeedbacks = lazy(() => import('./customer-feedback'));
const RtaTestRequest = lazy(() => import('./rta-test-request')); // newly added
const AappointmentSettigs = lazy(() => import('./appointment-settings'));
const SimulatorTrainingSchedule = lazy(() =>
  import('./simulator-training-schedule')
);
const MockTestSchedule = lazy(() => import('./mock-test-schedule'));
const InstructorIncentive = lazy(() => import('./instructor-incentive'));
const InstructorIncentiveProcessed = lazy(() =>
  import('./instructor-incentive-processed')
);
const StudentListReport = lazy(() => import('./student-report'));
const UpgradedStudentListReport = lazy(() =>
  import('./upgraded-student-report')
);
const TestScheduleReport = lazy(() => import('./test-schedule-report'));
const AssessementScheduleReport = lazy(() =>
  import('./assessment-schedule-report')
);
const ExaminerPerformanceReport = lazy(() =>
  import('./examiner-performance-report')
);

const instructorReport = lazy(() => import('./instructor-report'));

const ScheduleConfigurations = lazy(() => import('./schedule-configurations'));
const PreRegistrationConfigurations = lazy(() =>
  import('./preregistration-configurations')
);
const LectureAttendanceReport = lazy(() =>
  import('./lecture-attendance-report')
);

const Reports = lazy(() => import('./reports'));
const TicketTypeAssignment = lazy(() => import('./ticket-type-assignment'));

const Tickets = lazy(() => import('./ticket'));
const KnowledgeBase = lazy(() => import('./knowledge-base'));
const KnowledgeBaseAdmin = lazy(() => import('./knowledge-base-admin'));
const TestBookingDashboard = lazy(() => import('./test_booking-dashboard'));
const OnlineLectureAttendanceReport = lazy(() =>
  import('./online-lecture-attendance-report')
);

const Corporate = lazy(() => import('./corporate'));
const InternalExaminerPassingReport = lazy(() =>
  import('./internal-examiner-passing-report')
);
const ExternalLead = lazy(() => import('./external-leads'));
const customerWaitingForTrainingSchedules = lazy(() =>
  import('./customer-waiting-training-schedules')
);
const customerWaitingForRoadTest = lazy(() =>
  import('./customer-waiting-road-test')
);

const TimeSlot = lazy(() => import('./time-slot'));

const InstructorType = lazy(() => import('./instructor-type'));
const CacheManager = lazy(() => import('./cache-manager'));
const Queue = lazy(() => import('./queue'));
const ZohoReport = lazy(() => import('./zoho-report'));
const InstructorFirstAttemptPassingPerformanceReport = lazy(() =>
  import('./instructor-first-attempt-passing-performance-report')
);
const CsrStudentQuickRegistration = lazy(() =>
  import('./csr-student-quick-registration')
);
const PreRegistrationReport = lazy(() => import('./preregistration-report'));
const AppVersionUpdate = lazy(() => import('./app-version-update'));
const UtilizationLog = lazy(() => import('./utilization-logs'));
const StudentwiseUtilizationLog = lazy(() =>
  import('./studentwise-utilization-log')
);

const Driver = lazy(() => import('./driver'));
const Location = lazy(() => import('./location'));
const TripHoliday = lazy(() => import('./trip-holiday'));
const RoutesTimings = lazy(() => import('./routes-and-timing'));
const Trips = lazy(() => import('./trips'));

const Bus = lazy(() => import('./bus'));
const VehicleType = lazy(() => import('./vehicle-type'));
const VehicleModel = lazy(() => import('./vehicle-model'));
const ReferralBonus = lazy(() => import('./referral-bonus'));
const ReferraluserReport = lazy(() => import('./referral-user-report'));
const ReferralBonusConfiguration = lazy(() =>
  import('./referral-bonus-configuration')
);
const customerWaitingForTheoryTest = lazy(() =>
  import('./customer-waiting-theory-test')
);
const customerWaitingForYardTest = lazy(() =>
  import('./customer-waiting-yard-test')
);

const AppDeleteRequestReport = lazy(() =>
  import('./app-delete-request-report')
);
const GoldenOpportunityReport = lazy(() =>
  import('./golden-opportunity-report')
);
const StudentUtilizationReport = lazy(() =>
  import('./student-utilization-report')
);
const LpExpiryDateReport = lazy(() => import('./lp-expiry-report'));
const RtaExpiryDateReport = lazy(() => import('./rta-file-expiry-report'));
const NotificationSettings = lazy(() => import('./notification-settings'));

const EcoCtsSchedules = lazy(() => import('./eco-cts-supervisor-schedules'));
const CsrStudentTraining = lazy(() => import('./csr-student-training'));
const StudentTraining = lazy(() => import('./student-training'));
const EmaarDailySalesInfoLogs = lazy(() =>
  import('./emaar-daily-sales-info-logs')
);
const EmaarMonthlySalesInfoLogs = lazy(() =>
  import('./emaar-monthly-sales-info-logs')
);
const TrainingLectureTheory = lazy(() => import('./training-lecture-theory'));

const WorkingHoursTrainingTheory = lazy(() =>
  import('./working-hour-training-theory')
);
const TrainingLectureHallSchedule = lazy(() =>
  import('./training-lecture-hall-schedule')
);
const BulkScheduleTheoryTraining = lazy(() =>
  import('./bulk-schedule-theory-training')
);
const TrainingSchedulesRoutes = lazy(() => import('./training-schedules'));

const AbsenteesimeConfigurations = lazy(() =>
  import('./absenteesim-configurations')
);
const RTATestAddonConfigurations = lazy(() =>
  import('./rta-test-addon-configurations')
);
const Salesman = lazy(() => import('./salesman'));
const SalesmanCollectionReport = lazy(() =>
  import('./salesman-collection-report')
);

const RoadTestStartedPaymentDueReport = lazy(() =>
  import('./road-test-started-payment-due-report')
);
const RoadTestPassedAddonDueReport = lazy(() =>
  import('./road-test-passed-addon-due-report')
);
const CorporateRoadTestStartedPaymentDueReport = lazy(() =>
  import('./corporate-road-test-started-payment-due-report')
);
const CorporateRoadTestPassedAddonDueReport = lazy(() =>
  import('./corporate-road-test-passed-addon-due-report')
);
const TabbyConfigurations = lazy(() => import('./tabby-configurations'));
const ChangeTrainingBranchLog = lazy(() =>
  import('./change-training-branch-log')
);

const RoadEvaluationConfigurations = lazy(() =>
  import('./add-road-evaluation-configurations')
);
const ELiteCapLimitConfigurations = lazy(() =>
  import('./elite-cap-limit-configurations')
);
const EliteInstructorPassingReport = lazy(() =>
  import('./elite-instructor-passing-report')
);
const ELiteInstructorFirstAttemptPassingPerformanceReport = lazy(() =>
  import('./elite-instructor-first-attempt-passing-performance-report')
);
const EliteInstructorPassingPerformanceReport = lazy(() =>
  import('./elite-instructor-passing-performance-report')
);

const RTAPaymentInfo = lazy(() => import('./rta-payment-info'));

const ReasonSettings = lazy(() => import('./reason-settings'));
const ManageApprovalMatrix = lazy(() => import('./approval-matrix'));
const CourseRefundApprovalRequests = lazy(() =>
  import('./course-refund-approval-requests')
);
const InvoiceRefundApprovalRequests = lazy(() =>
  import('./invoice-refund-approval-requests')
);
const ClassCancellationApprovalRequests = lazy(() =>
  import('./class-cancellation-approval-requests')
);
const EnquiryRegistration = lazy(() => import('./enquiry-registration'));

class Routes extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <Suspense fallback={<ContainerLoader height={500} text="Loading..." />}>
          <Switch>
            <Route path="/auth" component={Auth} />
            <AuthenticateRoute exact path="/" component={Home} />

            <AuthenticateRoute path="/admin-users" component={AdminUser} />
            <AuthenticateRoute path="/erp-users" component={ErpUser} />
            <AuthenticateRoute path="/cache" component={CacheManager} />
            <AuthenticateRoute path="/queue" component={Queue} />
            <AuthenticateRoute path="/students" component={StudentRoutes} />
            {/* <AuthenticateRoute
              path="/students/upload-document/:id"
              component={StudentCrudUploadFile}
            />
            */}
            {/* */}
            {/* <AuthenticateRoute
              path="/students/:id"
              component={StudentCrudView}
            />  */}
            <AuthenticateRoute
              path="/supervisor/students"
              component={StudentStage}
            />
            <AuthenticateRoute
              path="/supervisor/schedules"
              component={SupervisorSchedules}
            />
            <AuthenticateRoute
              path="/supervisor/pending-schedules"
              component={PendingSchedules}
            />
            <AuthenticateRoute
              path="/schedule-configurations"
              component={ScheduleConfigurations}
            />

            <AuthenticateRoute
              path="/preregistration-configurations"
              component={PreRegistrationConfigurations}
            />

            <AuthenticateRoute
              path="/supervisor/stage-report"
              component={StudentStage}
            />
            <AuthenticateRoute
              path="/supervisor/leave"
              component={LeaveSummary}
            />
            <AuthenticateRoute path="/yards" component={Yard} />
            <AuthenticateRoute
              path="/supervisor-instructors"
              component={Home}
            />
            <AuthenticateRoute path="/license-type" component={LicenseType} />
            <AuthenticateRoute path="/lecture-hall" component={LectureHall} />
            <AuthenticateRoute path="/fee-codes" component={FeeCode} />
            <AuthenticateRoute
              path="/csr/new-registration"
              component={CsrStudentRegistration}
            />
            <AuthenticateRoute
              path="/csr/transfer"
              component={CsrStudentTransfer}
            />
            <AuthenticateRoute
              path="/customer-new-registration"
              component={StudentRegistration}
            />
            <AuthenticateRoute
              path="/transfer-students"
              component={StudentTransfer}
            />
            <AuthenticateRoute
              path="/refund-students"
              component={StudentRefund}
            />
            <AuthenticateRoute
              path="/csr/lead-qualification"
              component={CsrLeadQualification}
            />
            <AuthenticateRoute
              path="/csr/drop-off-registration"
              component={CsrDropOffRegistration}
            />
            <AuthenticateRoute
              path="/customer-drop-off-registration"
              component={CustomerDropOffRegistration}
            />
            <AuthenticateRoute
              path="/course-rule-management"
              component={TrainingAndTestingRequirement}
            />
            <AuthenticateRoute path="/country-group" component={CountryGroup} />
            <AuthenticateRoute path="/branch-types" component={BranchType} />
            <AuthenticateRoute
              path="/low-credit-settings"
              component={LowCreditSettings}
            />

            <AuthenticateRoute path="/branches" component={Branch} />
            <AuthenticateRoute path="/instances" component={Instance} />
            <AuthenticateRoute path="/document-type" component={DocumentType} />

            <AuthenticateRoute path="/courses" component={Courses} />

            <AuthenticateRoute path="/course-types" component={CourseTypes} />
            <AuthenticateRoute
              path="/course-type-incentive"
              component={CourseTypeIncentive}
            />
            <AuthenticateRoute path="/promotion" component={Promotions} />
            <AuthenticateRoute path="/company" component={Company} />

            <AuthenticateRoute
              path="/error-code-category"
              component={ErrorCodeCategory}
            />
            <AuthenticateRoute path="/error-codes" component={ErrorCode} />
            <AuthenticateRoute path="/instructors" component={Instructor} />
            <AuthenticateRoute
              path="/working-hours/practical-training"
              component={WorkingHoursPracticalTraining}
            />
            <AuthenticateRoute
              path="/working-hours/theory-training"
              component={WorkingHoursTheory}
            />

            <AuthenticateRoute
              path="/working-hours/mock-test"
              component={WorkingHoursMockTest}
            />
            <AuthenticateRoute
              path="/working-hours/simulator-training"
              component={WorkingHoursSimulatorTraining}
            />
            <AuthenticateRoute
              path="/supervisor/lecture-hall-schedule"
              component={LectureHallSchedule}
            />
            <Route
              path="/supervisor/mock-test-schedule"
              component={MockTestSchedule}
            />
            <Route
              path="/supervisor/instructor-incentive"
              component={InstructorIncentive}
            />
            <Route
              path="/supervisor/instructor-incentive-processed"
              component={InstructorIncentiveProcessed}
            />

            <Route
              path="/supervisor/simulator-training-schedule"
              component={SimulatorTrainingSchedule}
            />

            <AuthenticateRoute
              path="/lecture-theory"
              component={LectureTheory}
            />
            <AuthenticateRoute
              path="/api-access-logs"
              component={ApiAccessLogs}
            />
            <AuthenticateRoute path="/languages" component={Language} />
            <AuthenticateRoute path="/ris-languages" component={RisLanguage} />
            <AuthenticateRoute path="/tax-classes" component={TaxClass} />
            <AuthenticateRoute path="/holidays" component={Holiday} />
            <AuthenticateRoute
              path="/payment-reports"
              component={PaymentReport}
            />
            <AuthenticateRoute
              path="/integrator-traininginfo"
              component={TrainingInfo}
            />
            <AuthenticateRoute
              path="/integrator-paymentclearance"
              component={PaymentClearance}
            />

            <AuthenticateRoute
              path="/csr/dashboard"
              component={PrivilegedCSR}
            />
            <AuthenticateRoute
              path="/cts-studentprofileinfo"
              component={CTSStudentProfileInfo}
            />
            <AuthenticateRoute
              path="/cts-scheduleclass"
              component={CTSScheduleClass}
            />
            <AuthenticateRoute
              path="/cts-assessmentbooking"
              component={CTSAssessmentBookingInfo}
            />
            <AuthenticateRoute
              path="/ramadan-settings"
              component={RamadanSettings}
            />
            <AuthenticateRoute path="/cashier/dashboard" component={Cashier} />
            <AuthenticateRoute
              path="/cts-lectureattendanceinfo"
              component={CTSLectureAttendanceInfo}
            />
            <AuthenticateRoute
              path="/csr/collection-report"
              component={CSRCollectionReport}
            />
            <AuthenticateRoute
              path="/integrator-trainingdata"
              component={TrainingData}
            />
            <AuthenticateRoute
              path="/cts-assessmentresult"
              component={CTSAssessmentResult}
            />
            <AuthenticateRoute
              path="/cts-attendanceresult"
              component={CTSAttendanceResult}
            />
            <AuthenticateRoute
              path="/cts-instructoralert"
              component={CTSInstructorAlert}
            />
            <AuthenticateRoute path="/di-studentcdd" component={StudentCDD} />
            <AuthenticateRoute path="/di-examdetails" component={ExamDetails} />
            <AuthenticateRoute
              path="/di-reschedulewarningmessage"
              component={RescheduleWarning}
            />
            <AuthenticateRoute
              path="/integrator-dashboard"
              component={IntegratorDashboard}
            />
            <AuthenticateRoute
              path="/integrator-logs"
              component={IntegratorLog}
            />
            <AuthenticateRoute
              path="/supervisor/upload-training-info"
              component={UploadTrainingInfo}
            />
            <AuthenticateRoute
              path="/supervisor/change-instructor-of-schedules"
              component={ChangeInstructorOfSchedules}
            />
            <AuthenticateRoute path="/contract" component={Contract} />

            <AuthenticateRoute
              path="/cts-studentprofileinfo"
              component={CTSStudentProfileInfo}
            />
            <AuthenticateRoute
              path="/cts-scheduleclass"
              component={CTSScheduleClass}
            />
            <AuthenticateRoute
              path="/cts-assessmentbooking"
              component={CTSAssessmentBookingInfo}
            />
            <AuthenticateRoute
              path="/ramadan-settings"
              component={RamadanSettings}
            />
            <AuthenticateRoute path="/cashier/dashboard" component={Cashier} />
            <AuthenticateRoute
              path="/cts-lectureattendanceinfo"
              component={CTSLectureAttendanceInfo}
            />
            <AuthenticateRoute
              path="/csr/collection-report"
              component={CSRCollectionReport}
            />
            <AuthenticateRoute
              path="/integrator-trainingdata"
              component={TrainingData}
            />
            <AuthenticateRoute
              path="/cts-assessmentresult"
              component={CTSAssessmentResult}
            />
            <AuthenticateRoute
              path="/cts-attendanceresult"
              component={CTSAttendanceResult}
            />
            <AuthenticateRoute
              path="/cts-instructoralert"
              component={CTSInstructorAlert}
            />
            <AuthenticateRoute path="/di-studentcdd" component={StudentCDD} />
            <AuthenticateRoute path="/di-examdetails" component={ExamDetails} />
            <AuthenticateRoute
              path="/di-reschedulewarningmessage"
              component={RescheduleWarning}
            />
            <AuthenticateRoute
              path="/integrator-dashboard"
              component={IntegratorDashboard}
            />
            <AuthenticateRoute
              path="/integrator-logs"
              component={IntegratorLog}
            />
            <AuthenticateRoute path="/di-examresult" component={ExamResult} />

            <AuthenticateRoute
              path="/supervisor/upload-training-info"
              component={UploadTrainingInfo}
            />
            <AuthenticateRoute path="/contract" component={Contract} />
            <AuthenticateRoute
              path="/supervisor/dashboard"
              component={SupervisorDashboard}
            />
            <AuthenticateRoute path="/cts-sanityLog" component={SanityLog} />
            <AuthenticateRoute
              path="/cts-studentpermitdetails"
              component={CtsStudentPermitDetails}
            />
            <AuthenticateRoute
              path="/supervisor/training-schedule"
              component={TrainingSchedule}
            />
            <AuthenticateRoute
              path="/report/instructor-utilization"
              component={InstructorUtilizationReport}
            />
            <AuthenticateRoute
              path="/additional-training-log"
              component={AdditionalTrainingLog}
            />
            <AuthenticateRoute path="/sms-log" component={SmsLog} />

            <AuthenticateRoute
              path="/download-history"
              component={DownloadHistory}
            />
            <AuthenticateRoute path="/reports" component={Reports} />
            <AuthenticateRoute
              path="/report/registration"
              component={RegistrationReport}
            />
            <AuthenticateRoute path="/report/test" component={TestReport} />
            <AuthenticateRoute
              path="/report/mock/test"
              component={MockTestReport}
            />
            <AuthenticateRoute
              path="/report/instructor-api"
              component={InstructorApiReport}
            />
            <AuthenticateRoute
              path="/report/instructor-passing"
              component={InstructorPassingReport}
            />
            <AuthenticateRoute
              path="/report/instructor-passing-performance"
              component={InstructorPassingPerformanceReport}
            />
            <AuthenticateRoute
              path="/report/first-attempt-passing-performance"
              component={InstructorFirstAttemptPassingPerformanceReport}
            />
            <AuthenticateRoute path="/email-templates" component={CMSEmail} />
            <AuthenticateRoute
              path="/notification-templates"
              component={CMSNotification}
            />
            <AuthenticateRoute path="/sms-templates" component={CMSSms} />
            <AuthenticateRoute
              path="/supervisor/absent-from-cts"
              component={CTSAbsentClasses}
            />
            <AuthenticateRoute
              path="/api-role-permissions"
              component={ApiRolePermissionsView}
            />
            <AuthenticateRoute
              path="/api-permissions"
              component={ApiPermissionsView}
            />
            <AuthenticateRoute
              path="/instructor-rating/top-rated-instructors"
              component={TopRatedInstructors}
            />
            <AuthenticateRoute
              path="/instructor-rating/customer-rating-of-instructor"
              component={CustomerRatingOfInstructor}
            />
            <AuthenticateRoute
              path="/csr/appointments"
              component={BookAappointment}
            />
            <AuthenticateRoute
              path="/csr/customer-feedbacks"
              component={CustomerFeedbacks}
            />
            <AuthenticateRoute
              path="/csr/rta-test-request"
              component={RtaTestRequest}
            />
            <AuthenticateRoute
              path="/appointment-settings"
              component={AappointmentSettigs}
            />
            <AuthenticateRoute
              path="/report/student"
              component={StudentListReport}
            />
            <AuthenticateRoute
              path="/report/upgraded-students"
              component={UpgradedStudentListReport}
            />
            <AuthenticateRoute
              path="/report/test-schedule"
              component={TestScheduleReport}
            />
            <AuthenticateRoute
              path="/report/assessment-schedule"
              component={AssessementScheduleReport}
            />
            <AuthenticateRoute
              path="/report/examiner-performance-report"
              component={ExaminerPerformanceReport}
            />

            <AuthenticateRoute
              path="/report/instructor"
              component={instructorReport}
            />
            <Route path="/api-services" component={ServiceHeartBeat} />
            <Route path="/rpc-services" component={RPCHeartBeat} />
            <Route path="/corporate" component={Corporate} />
            <Route path="/corporate/report/test" component={TestReport} />
            <AuthenticateRoute
              path="/report/lecture-attendance"
              component={LectureAttendanceReport}
            />
            <AuthenticateRoute
              path="/ticket-type-assignments"
              component={TicketTypeAssignment}
            />
            <AuthenticateRoute path="/tickets" component={Tickets} />
            <AuthenticateRoute
              path="/test_booking/dashboard"
              component={TestBookingDashboard}
            />
            <AuthenticateRoute
              path="/knowledge-base"
              component={KnowledgeBase}
            />
            <AuthenticateRoute
              path="/admin/knowledge_base"
              component={KnowledgeBaseAdmin}
            />
            <AuthenticateRoute
              path="/report/online-lecture-attendance"
              component={OnlineLectureAttendanceReport}
            />
            <AuthenticateRoute
              path="/report/internal-examiner-performance"
              component={InternalExaminerPassingReport}
            />
            <AuthenticateRoute
              path="/csr/external-leads"
              component={ExternalLead}
            />
            <AuthenticateRoute path="/time-slot" component={TimeSlot} />

            <AuthenticateRoute
              path="/waiting-for-training"
              component={customerWaitingForTrainingSchedules}
            />
            <AuthenticateRoute
              path="/waiting-for-road-test"
              component={customerWaitingForRoadTest}
            />
            <AuthenticateRoute
              path="/instructor-type"
              component={InstructorType}
            />
            <AuthenticateRoute
              path="/report/zoho-report"
              component={ZohoReport}
            />
            <AuthenticateRoute
              path="/transportation/driver"
              component={Driver}
            />
            <AuthenticateRoute
              path="/transportation/locations"
              component={Location}
            />
            <AuthenticateRoute
              path="/transportation/trip-holiday"
              component={TripHoliday}
            />
            <AuthenticateRoute path="/transportation/bus" component={Bus} />
            <AuthenticateRoute
              path="/transportation/routes-timings"
              component={RoutesTimings}
            />
            <AuthenticateRoute path="/transportation/trip" component={Trips} />
            <AuthenticateRoute
              path="/transportation/vehicle-type"
              component={VehicleType}
            />
            <AuthenticateRoute
              path="/transportation/vehicle-model"
              component={VehicleModel}
            />
            <AuthenticateRoute
              path="/csr/quick-registration"
              component={CsrStudentQuickRegistration}
            />
            <AuthenticateRoute
              path="/report/pre-registration-report"
              component={PreRegistrationReport}
            />
            <AuthenticateRoute
              path="/app-version-update"
              component={AppVersionUpdate}
            />
            <AuthenticateRoute
              path="/referral-bonus"
              component={ReferralBonus}
            />

            <AuthenticateRoute
              path="/referral-user-report"
              component={ReferraluserReport}
            />
            <AuthenticateRoute
              path="/referral-bonus-configuration"
              component={ReferralBonusConfiguration}
            />
            <AuthenticateRoute
              path="/utilization-log"
              component={UtilizationLog}
            />
            <AuthenticateRoute
              path="/student-utilization-log"
              component={StudentwiseUtilizationLog}
            />
            <AuthenticateRoute
              path="/report/app-delete-request-report"
              component={AppDeleteRequestReport}
            />
            <AuthenticateRoute
              path="/waiting-for-theory-test"
              component={customerWaitingForTheoryTest}
            />
            <AuthenticateRoute
              path="/waiting-for-yard-test"
              component={customerWaitingForYardTest}
            />
            <AuthenticateRoute
              path="/report/golden-opportunity-report"
              component={GoldenOpportunityReport}
            />
            <AuthenticateRoute
              path="/report/student-utilization-report"
              component={StudentUtilizationReport}
            />
            <AuthenticateRoute
              path="/expiry-date-report/lp-expiry-report"
              component={LpExpiryDateReport}
            />
            <AuthenticateRoute
              path="/expiry-date-report/rta-file-expiry-report"
              component={RtaExpiryDateReport}
            />
            <AuthenticateRoute
              path="/supervisor/cts-schedules"
              component={EcoCtsSchedules}
            />

            <AuthenticateRoute
              path="/notification-settings"
              component={NotificationSettings}
            />
            <AuthenticateRoute
              path="/csr/training"
              component={CsrStudentTraining}
            />
            <AuthenticateRoute
              path="/training-students"
              component={StudentTraining}
            />
            <AuthenticateRoute
              path="/emaar-sales-report/daily-sales-report"
              component={EmaarDailySalesInfoLogs}
            />
            <AuthenticateRoute
              path="/emaar-sales-report/monthly-sales-report"
              component={EmaarMonthlySalesInfoLogs}
            />
            <AuthenticateRoute
              path="/training-lecture-theory"
              component={TrainingLectureTheory}
            />
            <AuthenticateRoute
              path="/working-hours/training-theory-training"
              component={WorkingHoursTrainingTheory}
            />
            <AuthenticateRoute
              path="/supervisor/training-lecture-hall-schedule"
              component={TrainingLectureHallSchedule}
            />
            <AuthenticateRoute
              path="/bulk-schedule-theory-training"
              component={BulkScheduleTheoryTraining}
            />
            <AuthenticateRoute
              path="/training/bulk-schedule"
              component={BulkScheduleTheoryTraining}
            />
            <AuthenticateRoute
              path="/training/schedule-listing"
              component={TrainingSchedulesRoutes}
            />
            <AuthenticateRoute
              path="/absenteesim-configurations"
              component={AbsenteesimeConfigurations}
            />
            <AuthenticateRoute
              path="/rta-test-addon-configurations"
              component={RTATestAddonConfigurations}
            />
            <AuthenticateRoute
              path="/salesman/dashboard"
              component={Salesman}
            />
            <AuthenticateRoute
              path="/report/salesman-collection-report"
              component={SalesmanCollectionReport}
            />
            <AuthenticateRoute
              path="/report/road-test-started-payment-due-report"
              component={RoadTestStartedPaymentDueReport}
            />
            <AuthenticateRoute
              path="/report/road-test-passed-addon-due-report"
              component={RoadTestPassedAddonDueReport}
            />
            <AuthenticateRoute
              path="/corporate-road-test-report/road-test-started-payment-due-report"
              component={CorporateRoadTestStartedPaymentDueReport}
            />
            <AuthenticateRoute
              path="/corporate-road-test-report/road-test-passed-addon-due-report"
              component={CorporateRoadTestPassedAddonDueReport}
            />
            <AuthenticateRoute
              path="/tabby-configurations"
              component={TabbyConfigurations}
            />
            <AuthenticateRoute
              path="/change-training-branch-log"
              component={ChangeTrainingBranchLog}
            />
            <AuthenticateRoute
              path="/add-road-evaluation-configurations"
              component={RoadEvaluationConfigurations}
            />
            <AuthenticateRoute
              path="/elite-cap-limit-configurations"
              component={ELiteCapLimitConfigurations}
            />
            <AuthenticateRoute
              path="/report/elite-instructor-passing"
              component={EliteInstructorPassingReport}
            />
            <AuthenticateRoute
              path="/report/elite-first-attempt-passing-performance"
              component={ELiteInstructorFirstAttemptPassingPerformanceReport}
            />
            <AuthenticateRoute
              path="/report/elite-instructor-passing-performance"
              component={EliteInstructorPassingPerformanceReport}
            />
            <AuthenticateRoute
              path="/rta-payment-info"
              component={RTAPaymentInfo}
            />
            <AuthenticateRoute
              path="/reason-settings"
              component={ReasonSettings}
            />
            <AuthenticateRoute
              path="/approval-matrix"
              component={ManageApprovalMatrix}
            />
            <AuthenticateRoute
              path="/approval-requests/full-course-refund"
              component={CourseRefundApprovalRequests}
            />
            <AuthenticateRoute
              path="/approval-requests/invoice-refund"
              component={InvoiceRefundApprovalRequests}
            />
            <AuthenticateRoute
              path="/approval-requests/class-cancellation"
              component={ClassCancellationApprovalRequests}
            />
            <AuthenticateRoute
              path="/enquiry-registration"
              component={EnquiryRegistration}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

export default Routes;
