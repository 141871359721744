import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { withTranslation } from 'react-i18next';
import Routes from './Routes';
import '../styles/base.scss';

class App extends Component {
  changeLanguage = (lng) => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng); // Dynamically switch language
  };

  render() {
    const { t, i18n } = this.props;

    const currentLanguage = i18n.language;

    return (
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
        />
        <Routes />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))
);
